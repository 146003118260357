import React from 'react'
import Reveal from '@solid-ui-components/Reveal';
import styled from '@emotion/styled';
import { Text, Flex, css, Box } from 'theme-ui'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';

const styles = {
  title: {
    fontWeight: 600,
    fontSize: `36px`,
    lineHeight: `48px`,
    marginBottom: `99px`,
    color: `#37426C`,
    '@media(max-width: 440px)': {
      fontSize: `26px`,
      marginBottom: `50px`
    },
    '@media(max-width: 1024px)': {
      marginBottom: `60px`
    }
  },
  subtitle: {
    fontWeight: 500,
    fontSize: `24px`,
    lineHeight: `35px`,
    marginBottom: `60px`,
    color: `#6A3EEA`,
    textAlign: `initial`,
    '@media(max-width: 1024px)': {
      textAlign: `center`
    },
    '@media(max-width: 440px)': {
      fontSize: `21px`
    }
  },
  text: {
    fontWeight: 300,
    fontSize: `20px`,
    lineHeight: `28px`,
    color: `#70778E`,
    maxWidth: `fit-content`,
    textAlign: `initial`,
    '@media(max-width: 1024px)': {
      textAlign: `center`,
    },
    '@media(max-width: 440px)': {
      fontSize: `19px`
    }
  },
  lastColumn: {
    padding: `48px 0px 48px 48px`
  }
}

const SubscriptionContainer = styled.div`
  background-color: #F4F5FF;
  height: auto;
  width: 100%;
  display: grid;
  text-align: center;
  padding: 105px 134px 120px 175px;
  position: relative;
  justify-content: center;
  align-items: center;
  @media(max-width: 1024px) {
    padding: 105px 50px 90px 50px
  }
  @media(max-width: 580px) {
    padding: 70px 20px 70px 20px;
  }
  @media(max-width: 440px) {
    padding: 40px 14px 10px 14px
  }
`;

const SubscriptionRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media(max-width: 1024px) {
    display: contents
  }
`

const SubscriptionColumn = styled(Reveal)`
  width: 50%;
  z-index: 1;
  @media(max-width: 1024px) {
    width: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
  }
`

const SmallImage = styled(Flex)`
  top: ${({index}) => {
    switch(index) {
      case 0: return '90px';
      case 1: return '280px';
      case 2: return '400px';
      case 3: return '650px';
      case 4: return '463px';
      case 5: return '520px';
      case 6: return '625px';
      case 7: return '740px';
      case 8: return '840px';
      case 9: return '650px';
    }
  }};
  left: ${({index}) => {
    switch(index) {
      case 0: return '82%';
      case 1: return '40%';
      case 2: return '49%';
      case 3: return '51%';
      case 4: return '22%';
      case 5: return '2%';
      case 6: return '0%';
      case 7: return '16%';
      case 8: return '32%';
      case 9: return '8%';
    }
  }};
  @media(max-width: 1024px) {
    display: ${({index})=> {
      if ([1,2,3,4,6,7,8,9].includes(index)) return 'none'
    }}
  }
`
const Subscription = ({content}) => {
  return (
    <SubscriptionContainer>
      <Reveal effect='fadeInDown'>
        <Text css={css(styles.title)}>{content.title.text}</Text>
      </Reveal>
      <Box sx={{maxWidth: '1040px'}}>
        <SubscriptionRow>
          <SubscriptionColumn>
            <Reveal effect='fadeInLeft'>
              <Text css={css(styles.subtitle)}>{content.text_1.text}</Text>
              <Text css={css(styles.text)}>{content.description.text}</Text>
            </Reveal>
          </SubscriptionColumn>
          <SubscriptionColumn style={styles.lastColumn}>
            <Reveal effect='fadeInRight'>
              <Img image={getImage(content.images[0].src.ImageSharp)} alt={content.images[0].alt}/>
            </Reveal>
          </SubscriptionColumn>
        </SubscriptionRow>
      </Box>
      { content.collection[0].images.map((image, index) => (
         <SmallImage index={index} sx={{position: 'absolute', top: 0, height: 'auto', width: 'auto'}}>
           <Img image={getImage(image.src.ImageSharp)} key={index}/>
         </SmallImage>
      ))}
    </SubscriptionContainer>
  )
}

export default Subscription
