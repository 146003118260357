//for new-landing
import React, { useState } from 'react';
import { Container, Flex, Box, css } from 'theme-ui';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import ContentText from '@solid-ui-components/ContentText';
import Reveal from '@solid-ui-components/Reveal';
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent';
import arrowRight from '../../../../../content/assets/icons/arrow-right.svg';
import ReactRotatingText from 'react-rotating-text';

const styles = {
  title: {
    fontSize: `48px`,
    lineHeight: `57px`,
    fontWeight: 600,
    marginBottom: `7px`,
    '@media(max-width: 440px)': {
      fontSize: `39px`,
      whiteSpace: `pre`,
    },
  },
  description: {
    fontSize: `48px`,
    lineHeight: `57px`,
    fontWeight: 300,
    '@media(max-width: 440px)': {
      fontSize: `43px`,
    },
  },
  subtitle: {
    fontSize: `18px`,
    marginTop: `22px`,
    lineHeight: `27px`,
    fontWeight: 300,
    marginBottom: `29px`,
    '@media(max-width: 440px)': {
      fontSize: `17px`,
    },
  },
  image: {
    height: `auto`,
    width: `auto`,
  },
  buttons: {
    '@media(max-width: 1024px)': {
      display: `grid`,
      justifyItems: `center`,
    },
  },
  container: {
    height: `432px`,
    '@media(min-width: 1024px)': {
      marginTop: 40,
    },
    '@media(max-width: 1024px)': {
      height: `auto`,
    },
    '@media(max-width: 639px)': {
      paddingTop: `4rem`,
    },
  },
};

const Image = styled(Flex)`
  z-index: -2;
  top: ${({ index }) => {
    switch (index) {
      case 0:
        return '190px';
      case 1:
        return '125px';
      case 2:
        return '600px';
      case 3:
        return '650px';
      case 4:
        return '400px';
      case 5:
        return '560px';
      case 6:
        return '400px';
      case 7:
        return '500px';
      case 8:
        return '450px';
      case 9:
        return '600px';
    }
  }};
  left: ${({ index }) => {
    switch (index) {
      case 0:
        return '0px';
      case 1:
        return '2%';
      case 2:
        return '70%';
      case 3:
        return '51%';
      case 4:
        return '68%';
      case 5:
        return '50%';
      case 6:
        return '70%';
      case 7:
        return '90%';
      case 8:
        return '50%';
      case 9:
        return '55%';
    }
  }};
  @media (max-width: 1024px) {
    display: ${({ index }) => {
      if ([3, 4, 5, 6].includes(index)) return 'none';
    }};
  }
`;

const MainImage = styled(Reveal)`
  position: absolute;
  width: 49%;
  left: 52%;
  max-width: 682px;
  margin-top: 40px;
  @media (max-width: 1024px) {
    position: inherit;
    width: 100%;
    max-width: fit-content;
    display: none;
  }
`;
const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 5px 10px 5px 25px;
  font-size: 1.1rem;
`;
const InputButton = styled.a`
  display: flex;
  flex: 0.15;
  height: 100%;
  background-color: #8f6bfa;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const HeroButton = styled.div`
  background-color: white;
  max-width: 500px;
  width: 100%;
  height: 60px;
  border: 2px solid #794bff;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  justify-content: ${({ index }) => {
    if (index === 2) return 'start';
    return 'center';
  }};
  align-items: center;
  margin: 3px;
  position: relative;
  color: ${({ index }) => {
    if (index === 2) return '#7D7D7D';
    return '#794BFF';
  }};
  font-size: ${({ index }) => {
    if (index === 2) return '16px';
    return '20px';
  }};
  cursor: pointer;
  @media (max-width: 440px) {
    margin-bottom: ${({ index }) => {
      if (index === 2) return '35px';
    }};
  }
  &:hover {
    transform: scale(1.08);
    transition: 0.3s ease-out;
  }
`;

const ButtonImage = styled.img`
  position: absolute;
  left: ${({ index }) => {
    if ([0, 1].includes(index)) return '15px';
  }};
  width: ${({ index }) => {
    if (index === 2) return '71px';
    if (index === '3') return '14px';
    return '27px';
  }};
  right: ${({ index }) => {
    if (index === 2) return '-2px';
    if (index === '3') return '25px';
  }};
`;

const ButtonText = styled.a`
  margin-left: ${({ index }) => {
    if (index === 2) return '25px';
  }};
`;

const HeroBlock01 = ({
  content: { title, description, text_1, images, buttons, collection, form },
  reverse,
}) => {
  const [value, setValue] = useState();
  return (
    <>
      <Container css={css(styles.container)}>
        <Flex
          sx={{
            alignItems: [null, null, null, `center`],
            flexDirection: [
              reverse ? `column-reverse` : `column`,
              null,
              null,
              `row`,
            ],
            marginLeft: [null, null, null, `17px`],
          }}
        >
          <MainImage>
            <Img image={images[0].src.ImageSharp} />
          </MainImage>
          <Box
            sx={{
              flexBasis: `2/4`,
              textAlign: [`center`, null, null, `left`],
            }}
          >
            <Reveal effect='fadeInDown'>
              <ContentText content={title} css={css(styles.title)} />
              <ReactRotatingText
                color='#794BFF'
                items={description.textGroup}
                css={css(styles.description)}
              />
              <ContentText content={text_1} css={css(styles.subtitle)} />
            </Reveal>
            {buttons && (
              <Reveal effect='fadeInRight' delay={1} css={css(styles.buttons)}>
                {buttons.map((button, index) => (
                  <HeroButton index={index} key={index}>
                    <ButtonImage index={index} src={button.icon.src} />
                    <ButtonText href={button.link} index={index}>
                      {button.text}
                    </ButtonText>
                    {index === 2 && <ButtonImage index='3' src={arrowRight} />}
                  </HeroButton>
                ))}
                {form.fields.map((field, index) => {
                  const params = new URL(form.buttons[0].link);
                  params.searchParams.set('email', value);
                  const newLink = params.origin + params.pathname + params.search;
                  return (
                    <HeroButton
                      css={{ cursor: 'auto' }}
                      index={index}
                      key={index}
                    >
                      <Input
                        onChange={({ target: { value: val } }) => {
                          setValue(val);
                        }}
                        name={field.identifier}
                        type={String(field.type).toLowerCase()}
                        css={{ flex: '0.85' }}
                        placeholder={field.placeholder.text}
                        required
                      />
                      <InputButton href={newLink}>
                        <img index='3' src={arrowRight} />
                      </InputButton>
                    </HeroButton>
                  );
                })}
              </Reveal>
            )}
          </Box>
          {collection[0].images.map((image, index) => (
            <Image
              index={index}
              sx={{
                position: 'absolute',
                top: 0,
                height: 'auto',
                width: 'auto',
              }}
            >
              <Img image={getImage(image.src.ImageSharp)} key={index} />
            </Image>
          ))}
        </Flex>
      </Container>
    </>
  );
};

export default WithDefaultContent(HeroBlock01);
