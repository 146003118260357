import React, { useRef, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { GatsbyImage as Img, getImage, StaticImage } from 'gatsby-plugin-image';
import Reveal from '@solid-ui-components/Reveal';
import { useHasBeenVisible } from '../../../hooks/useVisibility';
import FullWidthPlaceHolder from '../../../content/components/shared/FullWidthPlaceHolder';
import { Text, Flex, css } from 'theme-ui';
import Slider from 'react-slick';
import { Link } from 'gatsby';

const styles = {
  question: {
    fontWeight: 500,
    fontSize: `18px`,
    color: `#6A3EEA`,
    paddingTop: `10px`,
  },
  subtitle: {
    fontWeight: 300,
    fontSize: `21px`,
    color: `#70778E`,
    paddingTop: `10px`,
    paddingBottom: `60px`,
  },
  subLink: {
    textTransform: 'uppercase',
    color: '#6A3EEA',
    textDecoration: 'underline',
  },
  slickArrows: {
    height: `240px`,
    borderRadius: `10px`,
    border: `none`,
    background: `#F9F9FC`,
    boxShadow: `none`,
    cursor: `pointer`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
  },
  slickListSVG: {
    height: '100%',
    width: '100%',
  },
  innerArrow: {
    width: `58px`,
    height: `58px`,
    background: `#EAE8FE`,
    opacity: `0.5`,
    borderRadius: `8px`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    margin: `0 12px`,
    '@media(max-width: 540px)': {
      width: `29px`,
      height: `29px`,
      margin: `0 6px`,
    },
  },
  arrowBox: {
    width: `30px`,
    height: `30px`,
    borderRadius: `50%`,
    background: `#B2ABF9`,
    opacity: `0.5`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    ':hover': {
      opacity: 1,
    },
  },
};

const CardsWrapper = styled.div`
  height: auto;
  width: 100%;
  margin-top: 110px;
  text-align: center;
  position: relative;
  .slick-dots {
    display: none !important;
  }
  .slick-list {
    height: auto;
  }
  .slick-slider {
    width: 100% !important;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-end;
  }
  .center-slider {
    justify-content: center;
  }
  .new-slider {
    display: inline-flex;
    @media (min-width: 900px) {
      width: 800px !important;
    }
    @media (min-width: 1200px) {
      width: 1040px !important;
      margin-bottom: 50px;
    }
  }
  @media (max-width: 440px) {
    margin-bottom: 30px;
    margin-top: 80px;
  }
  @media (max-width: 800px) {
    margin-top: 60px;
  }
`;

const CardsTitle = styled(Text)`
  color: #37426c;
  margin-bottom: 0.5em;
  font-weight: 600;
  font-size: 36px;
  @media (max-width: 440px) {
    font-size: 24px;
  }
`;

const GroupCard = styled.a`
  padding: 0 5px;
`;

const SmallImage = styled(Flex)`
  z-index: ${({ index }) => {
    if ([0, 4].includes(index)) return 2;
    if (index === 6) return '-1';
  }};
  top: ${({ index }) => {
    switch (index) {
      case 0:
        return '660px';
      case 1:
        return '-80px';
      case 2:
        return '55px';
      case 3:
        return '0px';
      case 4:
        return '640px';
      case 5:
        return '85px';
      case 6:
        return '180px';
    }
  }};
  left: ${({ index }) => {
    switch (index) {
      case 0:
        return '20%';
      case 1:
        return '18%';
      case 2:
        return '63%';
      case 3:
        return '87%';
      case 4:
        return '23%';
      case 5:
        return '92%';
      case 6:
        return '80%';
    }
  }};
  @media (max-width: 768px) {
    z-index: ${({ index }) => {
      if ([2].includes(index)) return -1;
    }};
    display: ${({ index }) => {
      if ([0, 4].includes(index)) return 'none';
    }};
  } ;
`;

const CarouselArrow = ({ arrow, ...props }) => {
  return (
    <div style={styles.slickArrows} onClick={props.onClick}>
      <div css={css(styles.innerArrow)}>
        <div css={css(styles.arrowBox)}>
          {arrow === 'next' ? (
            <StaticImage src='../../../content/assets/new-landing/JoinGroup/arrow-right.png' />
          ) : (
            <StaticImage src='../../../content/assets/new-landing/JoinGroup/arrow-left.png' />
          )}
        </div>
      </div>
    </div>
  );
};

const SimpleSlider = ({ content }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [showSlides, setShowSlides] = useState();

  useEffect(() => {
    const updateWidth = () => setWidth(window.innerWidth);
    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    if (width <= 540) setShowSlides(1);
    if (width > 540 && width < 1024) setShowSlides(2);
    if (width > 1024) setShowSlides(content?.images?.length || 3);
  }, [showSlides]);
  const card = content.images.map((image, index) => (
    <GroupCard index={index} href={image.link} target='_blank' rel='noopener noreferrer'>
      <Img image={getImage(image.src.ImageSharp)} key={index} alt={image.alt} />
    </GroupCard>
  ));

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: showSlides,
    slidesToScroll: 1,
    nextArrow: <CarouselArrow arrow='next' />,
    prevArrow: <CarouselArrow arrow='prev' />,
  };
  return (
    <div style={{ display: 'contents' }}>
      <Reveal effect='fadeInUp'>
        {content.images.length <= showSlides ? (
          <div className='new-slider center-slider'>{card}</div>
        ) : (
          <Slider {...settings} className='new-slider' infinite={false}>
            {card}
          </Slider>
        )}
      </Reveal>
    </div>
  );
};

const JoinGroup = ({ content }) => {
  const publicRef = useRef();
  const hasScrolled = useHasBeenVisible(publicRef);
  
  return (
    <CardsWrapper>
      <Reveal effect='fadeInDown'>
        <CardsTitle>{content?.title?.text}</CardsTitle>
        <Text style={styles.question}>{content.description.text}</Text>
        <Text style={styles.subtitle}>{content.text_1.text}</Text>
      </Reveal>
      {hasScrolled ? (
        <SimpleSlider content={content} />
      ) : (
        <FullWidthPlaceHolder
          reference={publicRef}
          customStyle={{
            height: '270px !important',
            backgroundColor: 'transparent',
          }}
        />
      )}
      <Text style={styles.subLink} variant='h5'>
        <Link to={content.buttons[0].link} target='_blank' rel='noopener noreferrer'>
          {content.buttons[0].text}
        </Link>
      </Text>
      {content.collection[0].images.map((image, index) => (
        <SmallImage
          index={index}
          sx={{ position: 'absolute', top: 0, height: 'auto', width: 'auto' }}
        >
          <Img
            image={getImage(image.src.ImageSharp)}
            key={index}
            alt={image.alt}
          />
        </SmallImage>
      ))}
    </CardsWrapper>
  );
};

export default JoinGroup;
