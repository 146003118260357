import React from 'react'
import Reveal from '@solid-ui-components/Reveal';
import styled from '@emotion/styled';
import { Text, Flex, css, Link } from 'theme-ui'
import ContentButtons from '@solid-ui-components/ContentButtons';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';

const styles = {
  info: {
    fontFamily: `Lato, sans-serif`,
    fontWeight: 400,
    fontSize: `14px`,
    lineHeight: `25px`,
    color: `#FCFCFC`,
    marginTop: `12px`,
    marginBottom: `6px`,
    whiteSpace: `break-spaces`
  },
  image: {
    marginRight: `30px`
  },
  title: {
    fontWeight: 700,
    color: `#9A98FD`,
    fontSize: `22px`
  },
  link: {
    color: `#FCFCFC`,
    ':hover': {
      color: '#6A3EEA'
    }
  },
  wrapperStyles: {
    a: {
      padding: `0.5rem 0.5rem 0.5rem 0`
    }
  },
  trustPilot: {
    justifyContent: `space-around`,
    marginTop: `20px`,
    '@media(max-width: 640px)': {
      justifyContent: `center`,
      marginTop: `30px`,
    }
  }
}

const FooterContainer = styled.div`
  background-color: #37426C;
  height: auto;
  padding: 231px 73px 172px 73px;
  position: relative;

  @media(max-width: 800px) {
    padding: 100px 50px 44px 50px
  }
  @media(max-width: 440px) {
    padding: 40px 30px 0px
  }
`;

const FooterRow = styled.div`
  display: flex;
  flex-wrap: wrap
`

const FooterColumn = styled(Reveal)`
  width: ${({index}) => {
    switch(index) {
      case 0: return '40%';
      case 1: return '20%';
      case 2: return '20%';
      case 3: return '20%';
    }
  }};
  z-index: 1;
  margin-bottom: 30px;
  @media(max-width: 900px) {
    width: 100%
  }
`

const SmallImage = styled(Flex)`
  top: ${({index}) => {
    switch(index) {
      case 0: return '200px';
      case 1: return '80px';
      case 2: return '330px';
      case 3: return '395px';
    }
  }};
  left: ${({index}) => {
    switch(index) {
      case 0: return '0%';
      case 1: return '3%';
      case 2: return '87%';
      case 3: return '79%';
    }
  }};
  @media(max-width: 1024px) {
    display: ${({index}) => {
      if ([2,3].includes(index)) return 'none'
    }}
  }
`
const NewFooter = ({content}) => {
  return (
    <FooterContainer>
      <FooterRow>
        {content.collection.map((block, index) => (
          <FooterColumn index={index} key={index}>
            {index === 0 ? (
              <>
                <Link href={block.images[0].link}>
                  <Img image={getImage(block.images[0].src.ImageSharp)} alt={block.images[0].alt}/>
                </Link>
                <Text style={styles.info}>{block.text_1.text}</Text>
                <Text style={styles.info}>{block.text_2.text}</Text>
                <FooterRow>
                  <Link href={block.images[1].link}>
                    <Img image={getImage(block.images[1].src.ImageSharp)} alt={block.images[1].alt} style={styles.image}/>
                  </Link>
                  <Link href={block.images[2].link}>
                    <Img image={getImage(block.images[2].src.ImageSharp)} alt={block.images[2].alt}/>
                  </Link>
                </FooterRow>
              </>
            ) : index === 3 ? (
              <>
                <Text style={styles.title}>{block.title.text}</Text>
                <Link href={block.text_1.text}>
                  <Text css={css(styles.link)}>{block.description.text}</Text>
                </Link>
              </>
            ) : (
              <>
                <Text style={styles.title}>{block.title.text}</Text>
                <ContentButtons
                  content={block.buttons}
                  variant='vertical'
                  wrapperStyles={styles.wrapperStyles}
                  space={0}
                />
              </>
            )}
          </FooterColumn>
        ))}
      </FooterRow>
      { content.images.map((image, index) => (
         <SmallImage index={index} sx={{position: 'absolute', top: 0, height: 'auto', width: 'auto'}}>
           <Img image={getImage(image.src.ImageSharp)} key={index}/>
         </SmallImage>
      ))}
    </FooterContainer>

  )
}

export default NewFooter
