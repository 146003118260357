import React from 'react'
import Reveal from '@solid-ui-components/Reveal';
import SVG from 'react-inlinesvg'
import styled from '@emotion/styled';
import { Text, Flex, css } from 'theme-ui'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';

const styles = {
  title: {
    fontWeight: 600,
    fontSize: `36px`,
    lineHeight: `48px`,
    marginBottom: `99px`,
    color: `#37426C`,
    '@media(max-width: 440px)': {
      fontSize: `24px`
    },
    '@media(max-width: 1024px)': {
      marginBottom: `50px`
    }
  },
  subtitle: {
    fontWeight: 500,
    fontSize: `24px`,
    lineHeight: `35px`,
    marginBottom: `35px`,
    color: `#6A3EEA`,
    alignSelf: `end`,
    '@media(max-width: 440px)': {
      fontSize: `22px`
    }
  },
  text: {
    fontWeight: 300,
    fontSize: `21px`,
    lineHeight: `28px`,
    color: `#70778E`,
    '@media(max-width: 440px)': {
      fontSize: `19px`,
      marginBottom: `40px`
    }
  }
}

const ManageContainer = styled.div`
  background-color: white;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 105px 50px 120px 50px;
  @media(max-width: 1024px) {
    padding: 66px 20px
  }
  @media(max-width: 580px) {
    padding: 50px 20px 50px 20px
  }
  @media(max-width: 440px) {
    padding: 40px 14px 10px 14px
  };
`;

const Block = styled(Reveal)`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({index}) => {
    if (index === 1) return 'row-reverse'
  }};
  width: ${({index}) => {
    if (index === 0) return 'auto'
  }};
  margin-bottom: ${({index}) => {
    switch(index) {
      case 0: return '50px !important';
      case 1: return '-50px'
    }
  }};

  @media(max-width: 1024px) {
    padding: 2rem;
    height: ${({index})=> {
      if (index === 0) return '231px !important';
      return 'auto'
    }
  };

  @media(max-width: 660px) {
    display: contents;
    flex-direction: inherit;
    height: auto;
    margin-bottom: 0
  };
`

const ManageColumn = styled.div`
  max-width: 750px;
  width: 100%;
  z-index: 1
`

const TextContainer = styled.div`
  display: grid;
  justify-content: flex-start;
  text-align: initial;
  margin-left: ${({index}) => {
    if ([0,2].includes(index)) return '90px'
  }};
  white-space: break-spaces;
  @media(max-width: 660px) {
    text-align: center;
    margin-top: ${({index}) => {
      if ([0,2].includes(index)) return `40px`
    }};
    display: block;
    padding-left: 0;
    margin-left: 0;
    padding-right: 0;
    margin-bottom: 20px
  };
  @media(max-width: 370px) {
    white-space: break-spaces
  }
`

const SmallImage = styled(Flex)`
  top: ${({index}) => {
    switch(index) {
      case 0: return '60px';
      case 1: return '170px';
      case 2: return '420px';
      case 3: return '1160px';
      case 4: return '570px';
      case 5: return '150px';
      case 6: return '240px';
      case 7: return '240px';
      case 8: return '430px';
      case 9: return '430px';
      case 10: return '550px';
      case 11: return '820px';
      case 12: return '760px';
      case 13: return '1080px';
      case 14: return '1150px';
      case 15: return '1220px';
      case 16: return '1200px';
    }
  }};
  left: ${({index}) => {
    switch(index) {
      case 0: return '12%';
      case 1: return '51%';
      case 2: return '25%';
      case 3: return '74%';
      case 4: return '22%';
      case 5: return '5%';
      case 6: return '28%';
      case 7: return '69%';
      case 8: return '49%';
      case 9: return '75%';
      case 10: return '77%';
      case 11: return '92%';
      case 12: return '89%';
      case 13: return '78%';
      case 14: return '65%';
      case 15: return '7%';
      case 16: return '10%';
    }
  }};
  z-index: ${({index}) => {
    if ([15,16].includes(index)) return 1
  }};
  @media(max-width: 1024px) {
    display: ${({index})=> {
      if ([1,2,3,4,6,7,8,9,11,14,13,15,16].includes(index)) return 'none'
    }}
  }
`

const ManageWrapper = styled.div`
  position: relative
`

const IllustrationBlock = ({block, index}) => {
  return (
    <Reveal effect='fadeInGrow'>
      <Block index={index}>
        <SVG src={block.icon.src} />
        <TextContainer index={index}>
          <Text css={css(styles.subtitle)}>{block.title.text}</Text>
          <Text css={css(styles.text)}>{block.description.text}</Text>
        </TextContainer>
      </Block>
    </Reveal>
  )
}

const ManageGroup = ({content}) => {
  return (
    <ManageWrapper>
      <ManageContainer>
        <ManageColumn>
          <Reveal effect='fadeInDown'>
            <Text css={css(styles.title)}>{content.title.text}</Text>
          </Reveal>
          {content.collection.map((block, index) => (
            <IllustrationBlock key={index} index={index} block={block}/>
          ))}
        </ManageColumn>
      </ManageContainer>
      { content.images.map((image, index) => (
         <SmallImage index={index} sx={{position: 'absolute', top: 0, height: 'auto', width: 'auto'}}>
           <Img image={getImage(image.src.ImageSharp)} key={index}/>
         </SmallImage>
      ))}
    </ManageWrapper>
  )
}

export default ManageGroup
