import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import Reveal from '@solid-ui-components/Reveal';
import { useHasBeenVisible } from '../../../hooks/useVisibility';
import FullWidthPlaceHolder from '../../../content/components/shared/FullWidthPlaceHolder';
import { Text, Box, Flex } from 'theme-ui';
import { css } from '@emotion/react';

const styles = {
  count: {
    width: `58px`,
    height: `58px`,
    borderRadius: `50%`,
    backgroundColor: `#F9F9FC`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    position: `relative`,
    right: `18px`,
  },
};

const CardText = styled(Text)`
  font-weight: ${({ index }) => {
    if (index === 1) return '400';
    return '500';
  }};
  font-size: ${({ index }) => {
    switch (index) {
      case 0:
        return '24px';
      case 1:
        return '16px';
      case 2:
        return '21px';
      case 3:
        return '21px';
      case 4:
        return '18px';
    }
  }};
  text-align: center;
  color: ${({ index }) => {
    if (index === 0) return '#6A3EEA';
    return '#8D95AD';
  }};
  line-height: 28px;
  background-color: ${({ index }) => {
    if ([2, 3].includes(index)) return '#F3F7FF';
    return 'none';
  }};
  border-radius: 10px;
  margin-top: ${({ index }) => {
    switch (index) {
      case 0:
        return '20px';
      case 1:
        return '8px';
      case 2:
        return '13px';
      case 3:
        return '5px';
      case 4:
        return '13px';
    }
  }};
  padding: ${({ index }) => {
    if ([2, 3].includes(index)) return '8px 12px';
  }};
`;

const NetworkWrapper = styled.div`
  height: auto;
  width: 100%;
  margin-top: 105px;
  margin-bottom: 224px;
  display: grid;
  justify-items: center;
  padding: 0 10px;
  @media (max-width: 440px) {
    margin-top: 0;
    margin-bottom: 13px;
  }
  @media (max-width: 580px) {
    margin-bottom: 15px !important;
  }
  @media (max-width: 800px) {
    margin-top: 0 !important;
  }
  @media (max-width: 1024px) {
    margin-top: 60px;
    margin-bottom: 180px;
  }
`;

const NetworkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const NetworkTitle = styled(Text)`
  color: #37426c;
  margin-bottom: 0.5em;
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  width: 100%;
  padding-top: 36px;
  padding-bottom: 20px;
  z-index: 1;
  @media (max-width: 440px) {
    font-size: 24px;
  }
`;

const NetworkRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  max-width: 1080px;
  @media (max-width: 580px) {
    display: grid;
    justify-items: center;
  }
`;

const NetworkCard = styled.a`
  width: 33.3%;
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 0 15px 55px;
  z-index: 3;
  position: relative;
  @media (max-width: 580px) {
    width: 70%;
  }
`;

const ImageWrapper = styled(Box)`
  height: 58px;
  display: flex;
`;

const SmallImage = styled(Flex)`
  z-index: -1;
  top: ${({ index }) => {
    switch (index) {
      case 0:
        return '1430px';
      case 1:
        return '1270px';
      case 2:
        return '1800px';
      case 3:
        return '1300px';
      case 4:
        return '1080px';
      case 5:
        return '1280px';
      case 6:
        return '1790px';
      case 7:
        return '1660px';
      case 8:
        return '1360px';
    }
  }};
  left: ${({ index }) => {
    switch (index) {
      case 0:
        return '2%';
      case 1:
        return '18%';
      case 2:
        return '13%';
      case 3:
        return '83%';
      case 4:
        return '35%';
      case 5:
        return '37%';
      case 6:
        return '6%';
      case 7:
        return '33%';
      case 8:
        return '85%';
    }
  }};
  @media (max-width: 370px) {
    z-index: ${({ index }) => {
      if (index === 7) return -1;
    }};
  }
`;

const Subtitles = styled(Box)`
  display: flex;
  white-space: pre;
  justify-content: center;
  z-index: 1;
  @media (max-width: 980px) {
    display: grid;
    width: 213px;
    margin-bottom: 70px;
    white-space: break-spaces;
    text-align: center;
  }
`;

const NetworkColumns = ({ cards, humans, count }) => cards.map((card, index) => (
    <NetworkCard href={card.link} target="_blank" rel='noopener noreferrer'>
      <Reveal
        index={index}
        key={index}
        effect='fadeInPop'
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <ImageWrapper index={index}>
          <Img
            image={getImage({
              ...card.src.ImageSharp,
              __typename: 'ImageSharp',
            })}
            loading='lazy'
            alt={card.alt}
            style={{ width: '40px', height: '40px', marginTop: '8px' }}
          />

          <Img
            image={getImage({
              ...humans[index].src.ImageSharp,
              __typename: 'ImageSharp',
            })}
            alt={card.alt}
            style={{ width: '58px', borderRadius: '50%', zIndex: 1 }}
          />
          <Box style={styles.count}>{count[index]}</Box>
        </ImageWrapper>
        {card.value.map((text, textIndex) => (
          <CardText index={textIndex} key={textIndex}>
            {text}
          </CardText>
        ))}
      </Reveal>
    </NetworkCard>
  ));

const Subtitle = styled(Text)`
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  color: ${({ index }) => {
    if (index === 1) return '#6A3EEA';
    return '#70778E';
  }};
  margin-bottom: 125px;
  @media (max-width: 980px) {
    margin-bottom: 0;
  }
`;

const Network = ({ content }) => {
  const publicRef = useRef();
  const hasScrolled = useHasBeenVisible(publicRef);
  return (
    <NetworkWrapper>
      <Reveal effect='fadeInDown'>
        <NetworkTitle>{content?.title?.text}</NetworkTitle>
      </Reveal>
      <Subtitles>
        {content.description.textGroup.map((text, index) => (
          <Reveal effect='fadeInRight'>
            <Subtitle index={index} key={index}>
              {text}
            </Subtitle>
          </Reveal>
        ))}
      </Subtitles>

      {hasScrolled ? (
        <NetworkContainer>
          <NetworkRow>
            <NetworkColumns
              cards={content.images}
              humans={content.collection[0].images}
              count={content.collection[2].description.textGroup}
            />
          </NetworkRow>
        </NetworkContainer>
      ) : (
        <FullWidthPlaceHolder
          reference={publicRef}
          customStyle={{
            height: '270px !important',
            backgroundColor: 'transparent',
          }}
        />
      )}

      {content.collection[1].images.map((image, index) => (
        <SmallImage
          index={index}
          sx={{ position: 'absolute', top: 0, height: 'auto', width: 'auto' }}
        >
          <Img image={getImage(image.src.ImageSharp)} key={index} />
        </SmallImage>
      ))}
    </NetworkWrapper>
  );
};

export default Network;
