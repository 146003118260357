import React, { useState } from 'react';
import { Container, Flex, Box, Text, Input } from 'theme-ui';
import { GatsbyImage as Img, getImage, StaticImage } from 'gatsby-plugin-image';
import ContentContainer from '@solid-ui-components/ContentContainer';
import Reveal from '@solid-ui-components/Reveal';
import StackTitle from '@solid-ui-components/StackTitle';
import styled from '@emotion/styled';

const styles = {
  container: {
    textAlign: `center`,
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`,
    maxWidth: `1041px`,
    height: `200px`,
    '@media(max-width: 440px)': {
      paddingLeft: `1rem !important`,
      paddingRight: `1rem !important`,
    },
    '@media(max-width: 895px)': {
      height: `auto`,
    },
  },
};

const TitleWrapper = styled.p`
  margin: 0;
  margin-bottom: 1rem;
`;

const Title = styled.span`
  color: ${({ index }) => {
    if (index === 1) return '#6A3EEA';
  }};
  font-size: 36px;
  margin-left: ${({ index }) => index === 0 ? '0px' : '0.5rem'};
  @media (max-width: 1094px) {
    font-size: 30px;
  }
  @media (max-width: 440px) {
    font-size: 24px;
  }
`;

const SmallImage = styled(Flex)`
  top: ${({ index }) => {
    switch (index) {
      case 0:
        return '120px';
      case 1:
        return '40px';
      case 2:
        return '-35px';
      case 3:
        return '-50px';
      case 4:
        return '55px';
      case 5:
        return '-60px';
    }
  }};
  left: ${({ index }) => {
    switch (index) {
      case 0:
        return '8%';
      case 1:
        return '7%';
      case 2:
        return '28%';
      case 3:
        return '35%';
      case 4:
        return '53%';
      case 5:
        return '63%';
    }
  }};
  @media (max-width: 1094px) {
    display: ${({ index }) => {
      if ([0, 2, 4, 5].includes(index)) return 'none';
    }};
  } ;
`;

const SearchInput = styled(Input)`
  background: #ffffff;
  border: 2px solid #673be9;
  border-radius: 45px;
  font-size: 21px;
  padding: 0px 0px 0px 52px;
  height: 100%;
  color: #a79acc;
  ::-webkit-input-placeholder {
    color: #a79acc;
  }
  @media (max-width: 440px) {
    font-size: 16px;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const SearchContainer = styled.form`
  height: 65px;
  width: 505px;
  position: relative;
  z-index: 2;
`;

const SearchGroup = ({
  content: { container, title, text_1, images },
  lang,
}) => (
  <Container sx={styles.container}>
    <ContentContainer variant='cards.paper-md'>
      <StackTitle title={title} />
      <Reveal effect='fadeInGrow' delay={0.2}>
        <TitleWrapper>
          {title.textGroup.map((title, index) => (
            <Title index={index}>{title}</Title>
          ))}
        </TitleWrapper>
      </Reveal>
      <Reveal effect='fadeInGrow' delay={0.2}>
        <SearchWrapper>
          <SearchContainer action={container.link.link} method='GET'>
            <SearchInput
              name='sharing_group'
              placeholder={text_1.text}
              required
            />
            <StaticImage
              src='../../../content/assets/new-landing/SearchGroup/search-icon.png'
              css={{
                position: 'absolute',
                top: '20px',
                zIndex: 3,
                left: '20px',
              }}
            />
          </SearchContainer>
        </SearchWrapper>
      </Reveal>
      {images.map((image, index) => (
        <SmallImage
          index={index}
          sx={{ position: 'absolute', top: 0, height: 'auto', width: 'auto' }}
        >
          <Img image={getImage(image.src.ImageSharp)} key={index} />
        </SmallImage>
      ))}
    </ContentContainer>
  </Container>
);

export default SearchGroup;
