import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import Reveal from '@solid-ui-components/Reveal';
import { useHasBeenVisible } from '../../../hooks/useVisibility';
import FullWidthPlaceHolder from '../../../content/components/shared/FullWidthPlaceHolder';
import ContentButtons from '@solid-ui-components/ContentButtons'
import { Text, Flex, css } from 'theme-ui'

const styles = {
  text: {
    textAlign: `center`,
    color: `#A1AAC3`,
    fontWeight: 300,
    lineHeight: `28px`,
    fontSize: `18px`,
    paddingBottom: `74px`,
    '@media(max-width: 647px)': {
      paddingBottom: `20px`
    }
  },
  title: {
    textAlign: `center`,
    color: `#8A52E4`,
    fontWeight: 400,
    lineHeight: `35px`,
    fontSize: `24px`,
    paddingBottom: `42px`,
    '@media(max-width: 647px)': {
      paddingBottom: `15px`
    }
  },
  wrapperStyles: {
    display: `grid`,
    marginBottom: `129px`,
    width: `100%`,
    justifyContent: `center`,
    a: {
      justifyContent: `center`,
      '@media(max-width: 440px)': {
        justifyItems: `baseline`,
        width: `inherit`
      }
    },
    '@media(max-width: 1024px)': {
      justifyItems: `center`,
      marginBottom: `100px`
    },
    '@media(max-width: 800px)': {
      marginBottom: `80px`
    },
    '@media(max-width: 440px)': {
      marginBottom: `30px`
    }
  },
};

const GroupWrapper = styled.div`
  background-color: #EAE8FE;
  height: auto;
  width: 100%;
  margin-top: -38px;
  position: relative;
  @media(max-width: 440px) {
    padding: 1rem;
    margin-bottom: -30px
  }
  @media(max-width: 647px) {
    padding: 0 2rem
  }
`;

const GroupContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const GroupTitle = styled(Text)`
  z-index: 3;
  color: #37426C;
  margin-bottom: 0.5em;
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 77px;
  @media(max-width: 1024px) {
    padding-bottom: 26px
  }
  @media(max-width: 800px) {
    padding-top: 60px;
    padding-bottom: 40px
  }
  @media(max-width: 440px) {
    padding-top: 34px;
    padding-bottom: 40px;
    font-size: 24px
  }
`;

const GroupRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  max-width: 1080px;
  @media(max-width: 647px) {
    display: grid;
    justify-items: center
  }
`;

const GroupCard = styled(Reveal)`
  width: 33.3%;
  padding: 0 15px 30px;
  z-index: 3;
  @media(max-width: 646px) {
    width: 90%
  };
`;

const SmallImage = styled(Flex)`
  z-index: ${({index})=> {
    if([4,5,6].includes(index)) return 1
  }};
  top: ${({index}) => {
    switch(index) {
      case 0: return '100px';
      case 1: return '130px';
      case 2: return '210px';
      case 3: return '450px';
      case 4: return '790px';
      case 5: return '760px';
      case 6: return '815px';
    }
  }};
  left: ${({index}) => {
    switch(index) {
      case 0: return '0px';
      case 1: return '0%';
      case 2: return '80%';
      case 3: return '85%';
      case 4: return '7%';
      case 5: return '85%';
      case 6: return '87%';
    }
  }};
  @media(max-width: 1024px) {
    display: ${({index})=> {
      if ([1,2,4,6].includes(index)) return 'none'
    }}
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  position: relative;
  height: 70px;
  // @media(max-width: 647px) {
    // padding: 0 2rem
  // }
  @media(max-width: 440px) {
    height: 22px
  }
`

const ImageContainer = styled.div`
  width: 100%;
  display: inline-table;
  max-width: 796px;
  height: 100%;
  @media(max-width: 1024px) {
    width: 75%;
    height: auto
  };
  @media(max-width: 644px) {
    width: 95%;
    height: auto
  }
`

const GroupColumns = ({ info}) => {
  return (
    <>
      {info.map((block, index) => (
        <GroupCard index={index} key={index} effect='fadeInPop'>
          <Text css={css(styles.title)}>{block.title.text}</Text>
          <Text css={css(styles.text)}>{block.description.text}</Text>
        </GroupCard>
      ))}
    </>
  );
};

const CreateGroup = ({ content }) => {
  const publicRef = useRef();
  const hasScrolled = useHasBeenVisible(publicRef);
  return (
    <GroupWrapper>
      <Reveal effect='fadeInDown'>
        <GroupTitle>{content?.title?.text}</GroupTitle>
      </Reveal>
      {hasScrolled ? (
        <>
          <Reveal effect='fadeInUp'>
            <ImageWrapper>
              <ImageContainer>
                <img style={{width: `100%`, height: `100%`}} src={content.icon.src} />
              </ImageContainer>
            </ImageWrapper>
          </Reveal>
          <GroupContainer>
            <GroupRow>
              <GroupColumns info={content.collection[1].collection}/>
            </GroupRow>
          </GroupContainer>
        </>
      ) : (
        <FullWidthPlaceHolder
          reference={publicRef}
          customStyle={{ height: '270px !important', backgroundColor: 'transparent' }}
        />
      )}

      {content.buttons && (
        <Reveal
          effect='fadeInRight'
          delay={0.5}
          css={css({mb: [4, null, null, 0], display: 'flex', justifyContent: 'center'})}
        >
            <ContentButtons
              content={content.buttons}
              space={3}
              wrapperStyles={styles.wrapperStyles}
            />
        </Reveal>
      )}

      { content?.collection[0].images.map((image, index) => (
       <SmallImage index={index} sx={{position: 'absolute', top: 0, height: 'auto', width: 'auto'}}>
         <Img image={getImage(image.src.ImageSharp)} key={index}/>
       </SmallImage>
      ))}
    </GroupWrapper>
  );
};

export default CreateGroup;
