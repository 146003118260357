import React, { FC, useEffect } from 'react';
import { useMatch, useLocation } from '@reach/router';
import { withPrefix } from 'gatsby-link';

const FreshChat: FC = ({}) => {
  const { pathname } = useLocation();

  // const matched = useMatch('/it') || useMatch('/uk') || useMatch('/');

  const showFreshChat = () => {
    console.log('Open Fresh chat');
    window.fcWidget?.show();
  };
  const hideFreshChat = () => {
    console.log('Close Fresh chat');
    window.fcWidget?.close();
    window.fcWidget?.hide();
  };

  useEffect(() => {
    console.log('[FreshChat]', pathname);

    const timer = setInterval(() => {
      if (window.fcWidget?.isInitialized()) {
        showFreshChat();
        clearInterval(timer);
      }
    }, 250);

    return () => {
      clearInterval(timer);
      hideFreshChat();
    };
  }, []);

  return <div />;
};

export default FreshChat;
