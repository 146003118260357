import { normalizeBlockContentNodes } from '@blocks-helpers';
import CardList from '@components/CardList';
import flowUITheme from '@elegantstack/flow-ui-theme/src/theme';
import { Hero as HeroLayout } from '@layout';
import Header from '@solid-ui-blocks/Header/Block01';
import Hero from '@solid-ui-blocks/Hero/Block01';
import Divider from '@solid-ui-components/Divider';
import Seo from '@solid-ui-components/Seo';
import Layout from '@solid-ui-layout/Layout';
import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Box, Container, merge } from 'theme-ui';
import convertServiceToPost from '../../../functions/convertServiceToPost';
import CreateGroup from '../../components/new-landing/CreateGroup';
import JoinGroup from '../../components/new-landing/JoinGroup';
import ManageGroup from '../../components/new-landing/ManageGroup';
import Network from '../../components/new-landing/Network';
import NewFooter from '../../components/new-landing/NewFooter';
import SearchGroup from '../../components/new-landing/SearchGroup';
import Subscription from '../../components/new-landing/Subscription';
import Publications from '../../components/old-landing/Publications';
import styles from './_index.styles';
import theme from './_theme';
import FreshChat from "../../../content/components/FreshChat";

const updateValues = (object, transform) => {
  if (!object) return object;
  if (typeof object === 'object' && object.length) {
    return object.map((value, key) => transform(key, value))
  }
  return Object.fromEntries(Object.entries(object).map(([key, value]) => {
    return [key, transform(key, value)]
  }))
}

const utm = 'utm_url=new-landing&utm_source=blog&utm_medium=blog&utm_campaign=new-landing&utm_term=new-landing&utm_content=new-landing'
const addUtm = (_, value) => {
  if (typeof value === 'object') return updateValues(value, addUtm);
  if (typeof value !== 'string') return value;
  if (/http[s]?:\/\//.test(value)) {
    if (value.includes('?')) return `${value}&${utm}`
    return `${value}?${utm}`
  }
  return value;
}

const IndexPage = (props) => {
  const { allBlockContent } = props.data
  const services = convertServiceToPost({
    services: { ...props.data.serviceLandings },
  });
  const normalized = normalizeBlockContentNodes(allBlockContent?.nodes)
  const [content, setContent] = useState(addUtm(null, normalized));
  const lang = props.data.site.siteMetadata.locale;

  useEffect(() => {
    if (window.location.search.includes('utm_'))
      setContent(normalized);
  }, []);

  return (
    <Layout theme={merge(theme, { ...flowUITheme, colors: {} })} {...props}>
      <Seo title='Home' description="Home" />
      {content['new-header'] && (
        <>
          <Header content={content['new-header']} newLanding="true" />
          <Divider space='5' />
        </>
      )}
      {content['hero'] && (
        <Hero content={content['hero']} reverse />
      )}
      <Divider space='5' />
      {content['old-publications'] && (
        <Publications showVideo={lang === 'pt'} content={content['old-publications']} />
      )}
      {content['network'] && (
        <>
          <Network content={content['network']} />
        </>
      )}
      {content['search-group'] && (
        <>
          <Container variant='wide' sx={styles.featuresContainer}>
            <Divider space='-125px' />
            <SearchGroup lang={lang} content={content['search-group']} />
            {content['join-group'] && (
              <JoinGroup content={content['join-group']} />)}
          </Container>
        </>
      )}
      {services && content['old-serviceLandings'] &&
        <HeroLayout wide sx={{ bg: `contentBg`, pb: [3, 5], pt: [4, 5] }}>
          <Box sx={{ position: `relative`, zIndex: 2 }}>
            <CardList
              nodes={services}
              columns={[1, 2, 2, 3]}
              variant='vertical-cover'
              slider
              loading="lazy"
              autoPlay={false}
              title={content['old-serviceLandings'].kicker.text}
              aside
              withTitleLink
              customTitleLink="/servicelandings"
              customLinkPlaceholder={content['old-serviceLandings'].description.text}
              slidesToScroll={[1]}
            />
          </Box>
        </HeroLayout>
      }
      {content['create-group'] && (
        <CreateGroup content={content['create-group']} />
      )}
      {content['manage-group'] && (
        <ManageGroup content={content['manage-group']} />
      )}
      {content['subscription'] && (
        <Subscription content={content['subscription']} />
      )}
      {lang === 'it' && (<FreshChat/>)}
      { content['new-footer'] && (
        <NewFooter content={content['new-footer']} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query siteIndexBlockContent($blocksPaths: [String]!)  {
    allBlockContent: allBlockContent(filter: {
          page: { in: $blocksPaths },
          identifier: { in: [
              "new-header",
              "new-footer",
              "hero",
              "old-publications",
              "old-serviceLandings",
              "network",
              "search-group",
              "join-group",
              "create-group",
              "manage-group",
              "subscription",
          ] }
      }) {
      nodes {
        ...BlockContent
      }
    }
      site {
          siteMetadata {
              locale
          }
      }
      serviceLandings: allServiceLanding(filter: {featured: {eq: true}}, limit: 5) {
      nodes {
        id
        service {
          name
          slug
        }
        heroImage {
          __typename
          ... on ContentfulAsset {
            ContentfulAsset: gatsbyImageData(
              width: 500
              height: 375
              layout: FIXED
              cropFocus: TOP
              resizingBehavior: THUMB
              quality: 80
            )
            localFile_regular_mobile: localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  height: 375
                  layout: FIXED
                  transformOptions: { cropFocus: NORTH }
                  quality: 80
                )
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
